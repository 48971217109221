<script>
import { mapGetters } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import VTaskTable from "@/components/Task/VTaskTable";
import InnerDashCard from "@/components/Cards/InnerDashCard";
import VFilters from "@/components/Search/VFilters";

import { EventBus } from "@/libraries/EventBus";
import TaskService from "@/services/TaskService";
import ActivityService from "@/services/ActivityService";
import CustomerService from "@/services/CustomerService";
import UserService from "@/services/UserService";
import ProjectService from "@/services/ProjectService";
import MilestoneService from "@/services/MilestoneService";
import DepartmentService from "@/services/DepartmentService";

import DateRangeMixin from "@/mixins/DateRangeMixin";
import MGConsts from "@/mixins/MGConsts";
import AppCfg from "@/mixins/AppCfg";

export default {
    name: "VAllTasks",
    components: { VTaskTable, InnerDashCard, DateRangePicker, VFilters },
    mixins: [DateRangeMixin, AppCfg, MGConsts],
    data() {
        return {
            loading: false,
            initLoading: false, // for v-filters
            tasks: [],
            meta: {},
            stats: {},
            activities: [],
            customers: [],
            projects: [],
            milestones: [],
            departments: [],
            users: [],
            isBillable: [
                { label: this.$t("task.filter_billable"), value: "1" },
                { label: this.$t("task.filter_nonbillable"), value: "0" }
            ],
            taskStatus: [
                { label: this.$t("task.status_open"), value: "1" },
                { label: this.$t("task.status_closed"), value: "5" },
                { label: this.$t("task.status_invoiced"), value: "9" }
            ],
            isInternal: [
                { label: this.$t("task.filter_customer_related"), value: "0" },
                { label: this.$t("task.filter_internal_time"), value: "1" }
            ]
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId", "getAuthUser", "getIsAdmin"]),
        showMilestones() {
            if (this.getAuthUser.invoice_setup === 'projects' || this.getIsAdmin) {
                return true; 
            } else {
                return false;
            }
        }, 
        projectModels: function() {
            return this.appCfgRead("Projects.project_model_dropdown");
        },
        customer_id: function() {
            return this.apiSearchFilter.customer_id;
        },
        project_model: function() {
            return this.apiSearchFilter.project_model;
        },
        currentExportUrl: function() {
            let params = this.$buildParams();
            delete params.sort;
            delete params.direction;
            delete params.page;

            let url = new URL(
                location.protocol +
                    "//" +
                    location.host +
                    "/v1/tasks/csv-export"
            );
            for (let param in params) {
                if (param == "dateRange") {
                    url.searchParams.append(
                        param,
                        JSON.stringify(params[param])
                    );
                } else {
                    if (Array.isArray(params[param])) {
                        // PHP way
                        params[param].forEach((v, idx) => {
                            url.searchParams.append(`${param}[${idx}]`, v);
                        });
                    } else {
                        url.searchParams.append(param, params[param]);
                    }
                }
            }

            return url.href;
        }
    },
    watch: {
        customer_id: function(newValue) {
            if (newValue) {
                let params = { customer_id: newValue };
                if (this.apiSearchFilter.project_model) {
                    params[
                        "project_model"
                    ] = this.apiSearchFilter.project_model;
                }
                this.fetchProjects(params);
            } else {
                this.projects = [];
            }
        },
        project_model: function(newValue) {
            if (newValue) {
                let params = { project_model: newValue };
                if (this.apiSearchFilter.customer_id) {
                    params["customer_id"] = this.apiSearchFilter.customer_id;
                }
                this.fetchProjects(params);
            } else {
                this.projects = [];
            }
        }
    },
    created() {
        this.$aclGuard(this.$ACL.TIMEREG_MGMT);
        this.initLoading = true;
        this.fetchMilestones();
        this.resetSearch();
        this.$apiEnableWatcher();
        this.fetchData();
        this.initLoading = false;
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                params["task_stats"] = 1;

                const r = await TaskService.GET(params);
                this.tasks = r.data.data;
                this.meta = r.data.meta;
                this.stats = r.data.meta.stats;
            } catch (e) {
                console.log("fetchData exception", e);
            }

            this.loading = false;
        },
        async fetchSearchData() {
            await this.fetchActivities();
            await this.fetchCustomers();
            await this.fetchUsers();
            await this.fetchDepartments();
        },
        async fetchDepartments(loading = true) {
            try {                
                const r = await DepartmentService.dropdown();

                this.departments = r.data.data;
                console.log(this.departments, "deps");
            } catch (e) {
                console.log(e);
            }            
        },
        /**
         * Fetch milestones
         */
        async fetchMilestones() {
            try {
                const {
                    data
                } = await MilestoneService.GET_dropdown(
                    {includeAll: 1}
                );
                this.milestones = data.data;
            } catch (e) {
                console.log("fmi e", e);
            }
        },
        /**
         * Fetch users
         */
        async fetchUsers(loading = true) {
            try {
                const r = await UserService.dropdown();
                this.users = r.data.data;
            } catch (e) {}
        },
        /**
         * TBD: should be done user dependent
         */
        async fetchActivities(loading = true) {
            try {
                const r = await ActivityService.dropdown();
                this.activities = r.data.data;
            } catch (e) {}
        },
        /**
         * Get customers
         */
        async fetchCustomers() {
            try {
                const r = await CustomerService.dropdown();
                this.customers = r.data.data;
            } catch (e) {}
        },
        async fetchProjects(params) {
            try {
                const r = await ProjectService.GET_dropdown(params);
                this.projects = r.data.data;
            } catch (e) {
                console.log("fetchProjects E", e);
            }
        },

        async resetSearch() {
            this.apiSearchFilter = {
                dateRange: {
                    startDate: null,
                    endDate: null
                }
            };
            this.apiPaginator = {
                direction: "desc",
                limit: null,
                page: null,
                sort: "Tasks.task_date"
            };
        }
    }
};
</script>

<template>
    <div class="v-task-viewer">
        <base-header class="pb-1" type=""><bread-bag></bread-bag></base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-loader :loading="loading" v-if="loading" />
                    <!-- Stats -->
                    <div class="row" v-else>
                        <div class="col-12 col-md-4 col-lg-4">
                            <inner-dash-card
                                :title="$t('mytasks.total_time')"
                                icon="far fa-clock"
                            >
                                <template slot="default">
                                    {{ stats.used_time | toTime }}
                                </template>
                                <template slot="action"></template>
                            </inner-dash-card>
                        </div>
                        <div class="col-12 col-md-4 col-lg-4">
                            <inner-dash-card
                                :title="$t('mytasks.customer_related_time')"
                                icon="far fa-building"
                            >
                                <template slot="default"
                                    >{{ stats.customer_related_time | toTime }}
                                </template>
                                <template slot="action"
                                    >{{ $n(stats.customer_perc) }}%
                                    {{ $t("tasks.of_total") }}</template
                                >
                            </inner-dash-card>
                        </div>
                        <div class="col-12 col-md-4 col-lg-4">
                            <inner-dash-card
                                :title="$t('mytasks.internal_time')"
                                icon="far fa-house"
                            >
                                <template slot="default"
                                    >{{ stats.internal_time | toTime }}
                                </template>
                                <template slot="action"
                                    >{{ $n(stats.internal_perc) }}%
                                    {{ $t("tasks.of_total") }}</template
                                >
                            </inner-dash-card>
                        </div>
                    </div>
                    <!-- .Stats -->
                </div>
                <div class="col-12">
                    <card body-classes="px-0">
                        <!-- V-FILTERS -->
                        <div class="px-4">
                            <v-filters
                                :search="false"
                                :enable-filters="true"
                                :start-open="true"
                                @filters-open="fetchSearchData"
                                @reset-search="resetSearch"
                                @filter-view-change="$handleFilterView($event)"
                            >
                                <template slot="right">
                                    <v-loader
                                        :loading="initLoading"
                                        v-if="initLoading"
                                    />
                                    <div
                                        class="project-stats"
                                        v-else-if="stats"
                                    >
                                        <div class="project-stats__box">
                                            <div class="box-title">
                                                {{
                                                    $t("tasks.number_of_tasks")
                                                }}
                                            </div>
                                            <div class="box-data">
                                                {{ stats.total_tasks }}
                                            </div>
                                        </div>
                                        <div class="project-stats__box">
                                            <div class="box-title">
                                                {{ $t("tasks.billable_time") }}
                                            </div>
                                            <div class="box-data">
                                                {{
                                                    stats.billable_time | toTime
                                                }}
                                            </div>
                                        </div>
                                        <div class="project-stats__box">
                                            <div class="box-title">
                                                {{
                                                    $t(
                                                        "tasks.non_billable_time"
                                                    )
                                                }}
                                            </div>
                                            <div class="box-data">
                                                {{
                                                    stats.non_billable_time
                                                        | toTime
                                                }}
                                            </div>
                                        </div>
                                        <div class="">
                                            <a
                                                class="btn btn-primary mt-1"
                                                :class="{
                                                    disabled: tasks.length == 0
                                                }"
                                                target="_blank"
                                                :href="currentExportUrl"
                                                :outline="false"
                                                >{{
                                                    $t("tasks.export_to_csv")
                                                }}</a
                                            >
                                        </div>
                                    </div>
                                </template>
                                <!-- end of right slot -> stats -->
                                <!-- Default slot starts here -> filters -->
                                <div class="col-12 col-md-4">
                                    <date-range-picker
                                        class="range-picker"
                                        ref="picker"
                                        opens="right"
                                        :timePicker="false"
                                        :showWeekNumbers="true"
                                        :autoApply="true"
                                        v-model="apiSearchFilter.dateRange"
                                        :linkedCalendars="true"
                                        :locale-data="
                                            getDateRangePickerLocale()
                                        "
                                        :ranges="getDateRangePickerRanges()"
                                    >
                                        <template
                                            v-slot:input="picker"
                                            style="min-width: 350px"
                                        >
                                            <template v-if="picker.startDate">
                                                {{
                                                    $d(
                                                        picker.startDate,
                                                        "short"
                                                    )
                                                }}
                                                -
                                                {{
                                                    $d(picker.endDate, "short")
                                                }}
                                            </template>
                                        </template>
                                    </date-range-picker>
                                </div>
                                <!-- daterange -->
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.customer_id"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_customer'
                                            )
                                        "
                                        type="multiselect"
                                        :multiple="true"
                                        :options="customers"
                                    />
                                </div>
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.customer_subtype"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_segment'
                                            )
                                        "
                                        type="multiselect"
                                        :multiple="true"
                                        :options="mgCustomerSubtype"
                                    />
                                </div>
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.department_id"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_department'
                                            )
                                        "
                                        type="multiselect"
                                        :multiple="true"
                                        :options="departments"
                                    />
                                </div>
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        :disabled="projects.length == 0"
                                        v-model="apiSearchFilter.project_id"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_case'
                                            )
                                        "
                                        type="select"
                                        :options="projects"
                                    />
                                </div>
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.user_id"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_user'
                                            )
                                        "
                                        type="multiselect"
                                        :multiple="true"
                                        :options="users"
                                    />
                                </div>
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.project_model"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_case_type'
                                            )
                                        "
                                        type="select"
                                        :options="projectModels"
                                    />
                                </div>

                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.activity_id"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_activity'
                                            )
                                        "
                                        type="multiselect"
                                        :multiple="true"
                                        :options="activities"
                                    />
                                </div>
                                <div class="col-12 col-md-4" v-if="showMilestones">
                                    <FormulateInput
                                     v-model="apiSearchFilter.milestone_id"
                                        type="select"
                                        :placeholder="$t('invoice_line.milestone')"
                                        :options="milestones"
                                    />
                                </div>
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.status"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_status'
                                            )
                                        "
                                        type="select"
                                        :options="taskStatus"
                                    />
                                </div>
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.is_billable"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_billable'
                                            )
                                        "
                                        type="select"
                                        :options="isBillable"
                                    />
                                </div>
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.is_internal"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_time_type'
                                            )
                                        "
                                        type="select"
                                        :options="isInternal"
                                    />
                                </div>
                            </v-filters>
                        </div>

                        <!-- .V-FILTERS -->
                        <template v-if="!loading">
                            <v-task-table
                                :tasks="tasks"
                                :show-date="true"
                                :show-user="true"
                                :paginator="apiPaginator"
                                :enable-paginator="true"
                                :show-milestones="showMilestones"
                                @sort="$handleSorting($event)"
                                @refresh-meta="fetchData"
                            />
                            <div class="d-flex justify-content-center mt-4">
                                <div class="paginator">
                                    <base-pagination
                                        :page-count="
                                            meta.pagination.total_pages
                                        "
                                        :per-page="meta.pagination.per_page"
                                        :value="meta.pagination.current_page"
                                        :total="meta.pagination.total"
                                        @input="$handlePagination($event)"
                                    />
                                </div>
                            </div>
                        </template>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.table-responsive {
    overflow-x: auto !important;
}
</style>
