var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{class:{
        'text-success': _vm.status == 1, // open
        'text-warning': _vm.status == 5, // closed
        'text-danger': _vm.status == 9, // invoiced
    }},[(_vm.$slots.default)?_vm._t("default"):_c('i',{staticClass:"far fa-fw",class:{
    'fa-file-invoice-dollar': _vm.status == 9, 
    'fa-lock': _vm.status == 5, 
    'fa-lock-open': _vm.status == 1
    }})],2)}
var staticRenderFns = []

export { render, staticRenderFns }