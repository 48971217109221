<script>
export default {
    name: "VTaskStatus",
    props: {
        status: {},
    },
};
</script>

<template>
    <span
        :class="{
            'text-success': status == 1, // open
            'text-warning': status == 5, // closed
            'text-danger': status == 9, // invoiced
        }"
        ><slot name="default" v-if="$slots.default"></slot
    ><i class="far fa-fw"
    :class="{
        'fa-file-invoice-dollar': status == 9, 
        'fa-lock': status == 5, 
        'fa-lock-open': status == 1
        }" v-else></i></span>
</template>

<style>
</style>