<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import VTaskStatus from "@/components/Task/Widgets/VTaskStatus";
import VTaskForm from "@/components/Forms/VTaskForm";

import TaskService from "@/services/TaskService";
import { EventBus } from "@/libraries/EventBus";

export default {
    name: "VTaskTable",
    props: {
        showCloseDay: {
            type: Boolean,
            default: false,
            description: "Show close day button"
        },
        showUser: {
            type: Boolean,
            default: false,
            description: "Show user column"
        },
        showDate: {
            type: Boolean,
            default: false,
            description: "Show date column"
        },
        enableDateLinks: {
            type: Boolean,
            default: false,
            description: "Enable linking to dashboard from dates"
        },
        tasks: {
            description: "Task array"
        },
        enablePaginator: {
            type: Boolean,
            default: false
        },
        paginator: {
            description: "Support for paginator"
        },
        readOnly: {
            type: Boolean,
            default: false,
            description: "Set to `true` in order for read-only experience"
        },
        showMilestones: {
            type: Boolean,
            default: false
        }
    },
    components: {
        VTaskStatus,
        VTaskForm
    },
    data() {
        return {
            loading: true,
            showTaskModal: false,
            taskContext: { empty: true }
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId", "getAuthUser"]),
        preselectedDate: function() {
            if (this.selectedDate != "") {
                return this.selectedDate;
            } else {
                return this.today;
            }
        },
        today: function() {
            return dayjs().format("YYYY-MM-DD");
        }
    },
    methods: {
        convertDate(date) {
            return dayjs(date).format("YYYY-MM-DD");
        },
        /**
         * Close task modal
         */
        closeTaskModal() {
            this.showTaskModal = false;
        },
        closeSaveTaskModal(task) {
            this.showTaskModal = false;
            let idx = this.tasks.findIndex(t => {
                return t.id == task.id;
            });
            this.$set(this.tasks, idx, task);
            this.$emit("refresh-meta");
            EventBus.$emit("task-edited", task.id);
        },
        /**
         * Open up modal for editing & enable context
         */
        setTaskEditContext(task) {
            this.taskContext = task;
            this.showTaskModal = true;
        },
        /**
         * Show/hide delete option in the table
         */
        canDelete(task) {
            if (this.ACL_RW == false || task.user_id != this.getMyId) {
                return false;
            }

            if (task.is_closed || task.is_invoiced) {
                return false;
            }

            return true;
        },
        async handleDeleteTask(task) {
            this.$swal({
                icon: "warning",
                text: this.$t("task.delete_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    await this.deleteTask(task);
                    this.$notify({
                        message: this.$t("task.notify_task_deleted"),
                        timeout: 5000,
                        icon: "far fa-check",
                        type: "success"
                    });

                    let idx = this.tasks.findIndex(el => {
                        return el.id == task.id ? true : false;
                    });

                    this.tasks.splice(idx, 1);
                }
            });
        },
        /**
         * Delete the task
         * TBD TODO
         */
        async deleteTask(task) {
            // RUN DELETE ACTION
            // Find the task
            try {
                this.loading = true;
                const r = await TaskService.DELETE(task.id);
                this.$emit("task-delete", task.id);
                this.$emit("refresh-meta");
                EventBus.$emit("task-delete");
            } catch (e) {
                console.log(e);
                if ("errors" in e.response.data) {
                    this.$swal({
                        icon: "error",
                        title: this.$t("error_cannot_delete"),
                        text: map(e.response.data.errors, n => {
                            return n.join(".\n");
                        })
                    });
                } else {
                    this.$swal({
                        icon: "error",
                        title: this.$t("error_cannot_delete"),
                        text: this.$t("unknown_error")
                    });
                }
            }
        },
        sort(data) {
            this.$emit("sort", data);
        }
    }
};
</script>

<template>
    <div>
        <div class="table-responsive" v-if="tasks.length">
            <v-table class="table table-mg table-hover">
                <v-thead>
                    <v-th class="text-center"
                        ><i class="far fa-lock-open"></i
                    ></v-th>
                    <v-th v-if="showDate">
                        <v-sorter
                            :disabled="!enablePaginator"
                            :paginator="paginator"
                            name="Tasks.task_date"
                            @sort="sort"
                        >
                            {{ $t("dashboard.date") }}</v-sorter
                        >
                    </v-th>
                    <v-th v-if="showUser">
                        <v-sorter
                            :disabled="!enablePaginator"
                            :paginator="paginator"
                            name="Users.name"
                            @sort="sort"
                            >{{ $t("dashboard.user") }}</v-sorter
                        ></v-th
                    >
                    <v-th
                        ><v-sorter
                            :disabled="!enablePaginator"
                            :paginator="paginator"
                            name="Customers.name"
                            @sort="sort"
                            >{{ $t("dashboard.customer") }}</v-sorter
                        ></v-th
                    >
                    <v-th
                        ><v-sorter
                            :disabled="!enablePaginator"
                            :paginator="paginator"
                            name="Customers.customer_subtype"
                            @sort="sort"
                            >{{ $t("dashboard.segment") }}</v-sorter
                        ></v-th
                    >
                    <v-th
                        ><v-sorter
                            :disabled="!enablePaginator"
                            :paginator="paginator"
                            name="Projects.title"
                            @sort="sort"
                            >{{ $t("dashboard.case") }}</v-sorter
                        ></v-th
                    >
                    <v-th v-if="showMilestones">
                        <v-sorter
                            :disabled="!enablePaginator"
                            :paginator="paginator"
                            name="ProjectMilestones.name"
                            @sort="sort"
                            >{{ $t("dashboard.milestone") }}</v-sorter
                        ></v-th
                    >
                    <v-th>
                        <v-sorter
                            :disabled="!enablePaginator"
                            :paginator="paginator"
                            name="Activities.name"
                            @sort="sort"
                            >{{ $t("dashboard.activity") }}</v-sorter
                        ></v-th
                    >
                    <v-th class="text-center">
                        <v-sorter
                            :disabled="!enablePaginator"
                            :paginator="paginator"
                            name="Tasks.details"
                            @sort="sort"
                            >{{ $t("dashboard.notes") }}</v-sorter
                        ></v-th
                    >
                    <v-th class="text-center"
                        ><v-sorter
                            :disabled="!enablePaginator"
                            :paginator="paginator"
                            name="Tasks.is_billable"
                            @sort="sort"
                            >{{ $t("dashboard.billable") }}</v-sorter
                        ></v-th
                    >
                    <v-th class="text-right"
                        ><v-sorter
                            :disabled="!enablePaginator"
                            :paginator="paginator"
                            name="Tasks.time_reg"
                            @sort="sort"
                            >{{ $t("dashboard.time") }}</v-sorter
                        ></v-th
                    >
                    <v-th v-if="readOnly == false"></v-th>
                </v-thead>
                <v-tbody>
                    <v-tr v-for="t in tasks" :key="t.id">
                        <v-td class="text-center">
                            <v-task-status :status="t.status" />
                        </v-td>
                        <v-td v-if="showDate">
                            <router-link
                                v-if="enableDateLinks"
                                :to="{
                                    name: 'Dashboard',
                                    query: {
                                        showDate: convertDate(t.task_date)
                                    }
                                }"
                                >{{
                                    $d(new Date(t.task_date), "short")
                                }}</router-link
                            >
                            <template v-else>{{
                                $d(new Date(t.task_date), "short")
                            }}</template>
                        </v-td>
                        <v-td v-if="showUser">
                            {{ t.user.name }}
                        </v-td>
                        <v-td>
                            {{ t.customer.name }}
                        </v-td>
                        <v-td>
                            {{ t.customer.customer_subtype_name }}
                        </v-td>
                        <v-td>
                            <template v-if="t.project"
                                ><router-link
                                    :to="{
                                        name: 'ProjectViewTasks',
                                        params: {
                                            project_id: t.project_id
                                        }
                                    }"
                                    >{{ t.project.title }}</router-link
                                ></template
                            >
                            <template v-else
                                ><router-link
                                    :to="{
                                        name: 'CustomersTabInternalTime',
                                        params: {
                                            customer_id: t.customer_id
                                        }
                                    }"
                                    >({{
                                        $t("dashboard.internal_time")
                                    }})</router-link
                                ></template
                            >
                        </v-td>
                        <v-td>
                            <template v-if="'project_milestone' in t">
                                {{ t.project_milestone.name }}
                            </template>
                            <template v-else>---</template>
                        </v-td>
                        <v-td>
                            {{ t.activity.name }}
                        </v-td>
                        <v-td class="text-center">
                            <i
                                v-if="readOnly == false"
                                class="fa-sticky-note m-click"
                                @click="setTaskEditContext(t)"
                                :class="{
                                    'fas text-primary': t.details,
                                    'far text-muted': !t.details
                                }"
                            ></i>
                            <template v-else>
                                <v-empty :text="t.details" v-if="!t.details" />
                                <v-newlines :text="t.details" v-else />
                            </template>
                        </v-td>
                        <v-td class="text-center">
                            <template v-if="t.is_billable">{{
                                $t("yes")
                            }}</template>
                            <template v-else>{{ $t("no") }}</template>
                        </v-td>
                        <v-td class="text-right">
                            {{ t.time_reg | toTime }}
                        </v-td>
                        <v-td v-if="readOnly == false">
                            <base-dropdown
                                :menu-on-right="true"
                                :has-toggle="false"
                            >
                                <template slot="title-container"
                                    ><i class="far fa-ellipsis-h fa-lg"></i
                                ></template>
                                <div
                                    class="dropdown-item"
                                    @click="setTaskEditContext(t)"
                                >
                                    <i class="far fa-fw fa-pencil"></i>
                                    {{ $t("task.action_open_modal") }}
                                </div>
                                <div
                                    v-if="canDelete(t)"
                                    @click="handleDeleteTask(t)"
                                    class="dropdown-item text-danger"
                                >
                                    <i class="far fa-fw fa-trash"></i>
                                    {{ $t("task.action_delete") }}
                                </div>
                            </base-dropdown>
                        </v-td>
                    </v-tr>
                </v-tbody>
            </v-table>
        </div>
        <div class="px-4" v-else>
            <div class="alert alert-warning">
                {{ $t("dashboard.no_tasks_found_for_a_given_date") }}
            </div>
        </div>

        <portal to="shortcut-modal">
            <v-task-form
                v-if="showTaskModal"
                :context="taskContext"
                @close="closeTaskModal"
                @save="closeSaveTaskModal"
                :prefill-task-date="
                    taskContext.empty === true ? preselectedDate : ''
                "
            />
        </portal>
    </div>
</template>

<style></style>
